import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import 'react-inputs-validation/lib/react-inputs-validation.min.css'
import '../assets/sass/main.scss'
import AOS from 'aos'


class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isPreloaded: false
    }
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false })
      AOS.init({
        once: false,
        mirror: true,
        duration: 1000,
        easing: 'ease-in-out-back'
      })
    }, 100)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render () {
    const { children } = this.props
    const { isPreloaded } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Eventually' },
                { name: 'keywords', content: 'site, web' }
              ]}
            >
              <html lang='en' />
            </Helmet>
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
