import React from 'react'
import Icon from '../Icon'

export default function TopNav ({ title, onMenuClick = () => {} }) {
  return (
    <div id='headerToggle'>
      <a
        href="/#"
        className="toggle"
        onClick={e => {
          e.preventDefault();
          onMenuClick();
        }}
      >
        <Icon faCategory='fas' faIcon='bars' name='bars' />
      </a>
    </div>
  )
}
