module.exports = {
  siteTitle: 'Benedict Mendoza | Full Stack Developer / DevOps Engineer',
  manifestName: 'Benedict Mendoza',
  manifestShortName: 'Ben Mendoza',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ffffff',
  manifestThemeColor: '#007bff',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  firstName: 'Benedict',
  lastName: 'Mendoza',
  jobTitle: 'Full Stack Web Developer / DevOps Engineer',
  apiUrl: 'https://odjlnzl80k.execute-api.ca-central-1.amazonaws.com/p/contact',
  apiKey: 'QHIU1bGyQf4KEXunUi5DJaMkO8hi4dFxa35PjGvn',
  recaptchaKey: '6LcUWwEVAAAAAFE4MfrVLZv_okZoDlaAa6jPvyeF',
  socialLinks: [
    { category: 'fab', icon: 'github', name: 'Github', url: 'https://github.com/benedictmendoza' },
    { category: 'fab', icon: 'twitter', name: 'Twitter', url: 'https://twitter.com/koolben' },
    { category: 'fab', icon: 'linkedin', name: 'Linkedin', url: 'https://www.linkedin.com/in/benedictmendoza' },
    { category: 'fab', icon: 'facebook', name: 'Facebook', url: 'https://facebook.com/benedictangelomendoza' },
    { category: 'fas', icon: 'envelope', name: 'Email', url: 'mailto:mail.benedictmendoza@gmail.com' }
  ],
  sections: [
    { id: 'top', name: 'About' },
    { id: 'skills', name: 'Skills' },
    { id: 'experience', name: 'Experience' },
    { id: 'contact', name: 'Contact' }
  ],
  webDevelopment: [
    { icon: 'html5', category: 'fab', name: 'HTML5' },
    { icon: 'css3-alt', category: 'fab', name: 'CSS3' },
    { icon: 'js-square', category: 'fab', name: 'JavaScript' },
    { icon: 'php', category: 'fab', name: 'PHP' },
    { icon: 'mysql', category: 'fab', name: 'MySQL' },
    { icon: 'postgresql', category: 'fab', name: 'PostgreSQL' },
    { icon: 'mongodb', category: 'fab', name: 'MongoDB' },
    { icon: 'zend', category: 'fab', name: 'Zend Framework 2' },
    { icon: 'codeigniter', category: 'fab', name: 'CodeIgniter' },
    { icon: 'jquery', category: 'fab', name: 'JQuery' },
    { icon: 'react', category: 'fab', name: 'React' },
    { icon: 'redux', category: 'fab', name: 'Redux' },
    { icon: 'angular', category: 'fab', name: 'Angular' },
    { icon: 'vuejs', category: 'fab', name: 'Vue JS' },
    { icon: 'typescript', category: 'fab', name: 'TypeScript' },
    { icon: 'gatsby', category: 'fab', name: 'GatsbyJS' },
    { icon: 'bootstrap', category: 'fab', name: 'Bootstrap' },
    { icon: 'sass', category: 'fab', name: 'SASS' }
  ],
  api: [
    { icon: 'google', category: 'fab', name: 'Google Suite' },
    { icon: 'facebook', category: 'fab', name: 'Facebook' },
    { icon: 'twitter', category: 'fab', name: 'Twitter' },
    { icon: 'paypal', category: 'fab', name: 'Paypal' },
    { icon: 'stripe', category: 'fab', name: 'Stripe' },
    { icon: 'mailchimp', category: 'fab', name: 'MailChimp' }
  ],
  devOps: [
    {
      icon: 'aws',
      category: 'fab',
      name: 'AWS'
    },
    {
      icon: 'saltstack',
      category: 'fab',
      name: 'Saltstack'
    },
    {
      icon: 'jenkins',
      category: 'fab',
      name: 'Jenkins'
    },
    {
      icon: 'docker',
      category: 'fab',
      name: 'Docker'
    },
    {
      icon: 'jinja',
      category: 'fab',
      name: 'Jinja'
    },
    {
      icon: 'npm',
      category: 'fab',
      name: 'NPM'
    },
    {
      icon: 'node-js',
      category: 'fab',
      name: 'Node JS'
    },
    {
      icon: 'python',
      category: 'fab',
      name: 'Python'
    },
    {
      icon: 'git-alt',
      category: 'fab',
      name: 'Git'
    },
    {
      icon: 'bitbucket',
      category: 'fab',
      name: 'Bitbucket'
    },
    {
      icon: 'linux',
      category: 'fab',
      name: 'Linux'
    },
    {
      icon: 'nginx',
      category: 'fab',
      name: 'NGINX'
    },
    {
      icon: 'apache',
      category: 'fab',
      name: 'Apache'
    }
  ],
  others: [
    {
      icon: 'ubuntu',
      category: 'fab',
      name: 'Ubuntu'
    },
    {
      icon: 'redhat',
      category: 'fab',
      name: 'Red Hat'
    },
    {
      icon: 'centos',
      category: 'fab',
      name: 'CentOS'
    },
    {
      icon: 'windows',
      category: 'fab',
      name: 'Windows'
    },
    {
      icon: 'apple',
      category: 'fab',
      name: 'MacOsX'
    },
    {
      icon: 'chrome',
      category: 'fab',
      name: 'Chrome'
    },
    {
      icon: 'firefox',
      category: 'fab',
      name: 'Firefox'
    },
    {
      icon: 'photoshop',
      category: 'fab',
      name: 'Photoshop'
    }
  ]
}
