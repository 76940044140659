import React from 'react'
import { Link } from 'gatsby'

export default function Footer ({ title, heading, avatar }) {
  return (
    <div id='logo'>
      <Link to='/'>
        <span className='image centered'>
          <img height='128px' src={avatar} alt='' />
        </span>
        <h1 id='title'>Benedict Mendoza</h1>
        <p>Full Stack Developer / DevOps Engineer</p>
      </Link>
    </div>
  )
}
